"use client";
import React from "react";
import GeneralError from "@/src/components/GeneralError";
import { ROUTE_HOME } from "@/constants/routes";
import { LinkButton } from "@/src/components/buttons/components/LinkButton";
import { useMountEffect } from "@react-hookz/web";
import { BaseLayout } from "./components/BaseLayout";
import { SiteWrapper } from "@/src/components/grid/components/SiteWrapper";
import { DarkThemeStyle } from "@/src/components/Layout/DarkThemeStyle";
import { GridBackground } from "@/src/components/grid/components/GridBackground";

export default function Error({
  error,
  reset, //TODO: implement try again with new design
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useMountEffect(() => {
    if (window !== undefined) {
      window.newrelic?.noticeError({ ...error });
    }
  });

  return (
    <BaseLayout>
      <DarkThemeStyle />
      <GridBackground className="font-roobert flex flex-grow w-full  md:justify-center justify-start">
        <SiteWrapper
          className="flex flex-grow flex-col"
          innerClassName="flex flex-grow flex-col"
        >
          <GeneralError className="h-fit pt-20 pb-20">
            <LinkButton
              size={"sm"}
              href={ROUTE_HOME}
              className={"w-fit pr-16 md:text-xl"}
            >
              Continue Exploring
            </LinkButton>
          </GeneralError>
        </SiteWrapper>
      </GridBackground>
    </BaseLayout>
  );
}
